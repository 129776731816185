input:focus {
  outline-style: none;
}

input[type="text"]
{
  font-size: 1rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden;
}